import PropTypes from 'prop-types';
// material
import {styled} from '@mui/material/styles';
import {
    Toolbar,
    Tooltip,
    IconButton,
    Typography,
    OutlinedInput,
    InputAdornment,
    Button,
    Box, Grid, TextField, Autocomplete, Stack, Collapse, Drawer, Divider
} from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
import {useState} from "react";
import Container from "@mui/material/Container";

import {IArchiveFilterStatus} from "../../../models/IArchiveFilterStatus.ts";
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({theme}) => ({
    height: 30,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({theme}) => ({
    width: 240,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {width: 320, boxShadow: theme.customShadows.z8},
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
    },
}));

// ----------------------------------------------------------------------

ArchiveListToolbar.propTypes = {
    numSelected: PropTypes.number,
    filterName: PropTypes.string,
    onFilterName: PropTypes.func,
};

export default function ArchiveListToolbar({
                                               selected,
                                               numSelected,
                                               filterName,
                                               onFilterName,
                                               handleDeleteOpen,
                                               searchPlaceholderText,
                                               setArchiveStatus,
                                               archiveStatus,
                                               uploadButton
                                           }) {
    const [drawer, toggleDrawer] = useState(false);
    return (
        <>
            <RootStyle
                sx={{
                    ...(numSelected > 0 && {
                        color: 'primary.main',
                        bgcolor: 'primary.lighter',
                    }),
                }}
            >
                {numSelected > 0 ? (
                    <Typography component="div" variant="subtitle1">
                        {numSelected} selected
                    </Typography>
                ) : (
                    null
                )}

                <Container></Container>
                <Stack spacing={1.5} direction="row">
                    {numSelected > 0 ? (
                        <Tooltip title="Delete" onClick={() => {
                            handleDeleteOpen(selected);
                        }}>
                            <IconButton>
                                <Iconify icon="eva:trash-2-fill"/>
                            </IconButton>
                        </Tooltip>
                    ) : (
                        null
                    )}
                    {uploadButton}

                    <Button
                        variant="contained"
                        component="label"
                        color={"primary"}
                        startIcon={<Iconify icon="ic:round-filter-list"/>}
                        onClick={() => toggleDrawer(!drawer)}
                    >
                        Filter
                    </Button>

                </Stack>
            </RootStyle>

            {/* Filter list */}
            <Drawer anchor={"right"}
                    open={drawer}
                    onClose={() => toggleDrawer(false)}>
                <Stack sx={{p: 3}} spacing={3}>
                    <Typography variant="h6">Filters</Typography>
                    <Divider/>
                    <Stack spacing={1}>
                        <Typography variant="body1">Search archives: </Typography>
                        <SearchStyle
                            value={filterName}
                            onChange={onFilterName}
                            placeholder={searchPlaceholderText}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Iconify icon="eva:search-fill"
                                             sx={{color: 'text.disabled', width: 20, height: 20}}/>
                                </InputAdornment>
                            }
                        />
                    </Stack>

                    <Stack spacing={1}>
                        <Typography variant="body1">Archive status: </Typography>
                        <Autocomplete
                            multiple
                            id="combo-box-demo"
                            onChange={(e, value) => {
                                if (value.length != 0) // TODO: make sure he can't select nothing
                                    setArchiveStatus(value);
                            }}
                            options={[IArchiveFilterStatus.Processed, IArchiveFilterStatus.Deleted]}
                            defaultValue={[IArchiveFilterStatus.Processed, IArchiveFilterStatus.Deleted]}
                            renderInput={(params) => <TextField {...params} label="Status"/>}
                        />
                    </Stack>
                </Stack>
            </Drawer>
        </>
    );
}

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useContext, useEffect, useState} from "react";
import {sendGetShopifyWarehouse} from "../../../api/user";
import AuthContext from "../../../context/authContext";
import {TablePagination, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useSnackbar} from "notistack";


const ProductStockTable = ({warehouse}) => {
    const {user} = useContext(AuthContext);
    const [products, setProducts] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = React.useState(0);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const fetchData = async (warehouseId) => {
        const response = await sendGetShopifyWarehouse(user.token, warehouseId);
        return {response, data: await response.json()};
    };

    useEffect(() => {
        if (warehouse == null) {
            setProducts([]);
            return;
        }
        console.log(warehouse);
        fetchData(warehouse.id).then(({response, data}) => {
            if (response.ok)
                setProducts(data.products);
            else
                enqueueSnackbar(data.message, {variant: "error"});
        });
    }, [warehouse]);

    const handleChangePage = (
        event,
        newPage
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (products.length == 0)
        return <Box display="flex" justifyContent="flex-start">
            <Typography variant="h4" textAlign="center">
                No products found for this warehouse.
            </Typography>
        </Box>;

    return (
        <Paper>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{width: "50px"}}>Id</TableCell>
                            <TableCell sx={{width: "200px"}}>Name</TableCell>
                            <TableCell sx={{width: "50px"}}>Quantity</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {products.map((row) => (
                            <TableRow
                                key={row.name}

                            >
                                <TableCell>
                                    {row.productId}
                                </TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.quantity}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>


                </Table>
            </TableContainer>

        </Paper>
    );
}

export default ProductStockTable;

import {useContext, useState} from "react";
import AuthContext from "../../../context/authContext";
import {Button, Modal, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {Icon} from "@iconify/react";
import {sendCreateShopifyProduct} from "../../../api/user";
import {useSnackbar} from "notistack";

const CreateProduct = ({shown, setShown}) => {
    const {user} = useContext(AuthContext);
    const [name, setName] = useState("");
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const handleCreateProduct = async () => {
        const response = await sendCreateShopifyProduct(user.token, name);

        if (response.ok)
            enqueueSnackbar((await response.json()).message, {variant: "success"});
        else
            enqueueSnackbar((await response.json()).message, {variant: "error"});

        setShown(false);
        setName("");
    };

    return (
        <Modal
            open={shown}
            onClose={() => setShown(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
                display: "flex", justifyContent: "center", alignItems: "center"
            }}
        >
            <Box sx={{
                width: "400px",
                backgroundColor: "white",
                p: 2,
                justifyContent: "center",
                alignItems: "center"
            }}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <Typography variant="h4">Add product</Typography>
                    <Box component={Icon} icon={"mdi:close"} width={30} height={30} onClick={() => {
                        setShown(false);
                    }}/>
                </div>
                <Box sx={{
                    backgroundColor: "white",
                    pt: 3,
                    pb: 1.5,
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <Stack spacing={2.5}>
                        <TextField
                            fullWidth
                            label="Product Name"
                            variant="filled"
                            size="small"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                        />
                        <Button fullWidth variant="contained" onClick={() => handleCreateProduct()}>Create</Button>
                    </Stack>
                </Box>
            </Box>
        </Modal>
    );
}

export default CreateProduct;

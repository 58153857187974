import {useContext, useEffect, useState} from 'react';
// material
import {Container, Stack, Typography} from '@mui/material';
// components
import Page from '../components/Page';
import {PackageSort, PackageList, PackageCartWidget, PackageFilterSidebar} from '../sections/@dashboard/packages';
import {sendGetShopifyPackages} from "../api/user";
import AuthContext from "../context/authContext";
import {useSnackbar} from "notistack";
import {API_URL} from "../api/constants";

// ----------------------------------------------------------------------

export default function Packages() {
    const [openFilter, setOpenFilter] = useState(false);
    const {user} = useContext(AuthContext);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [packages, setPackages] = useState([]);

    const handleOpenFilter = () => {
        setOpenFilter(true);
    };

    const handleCloseFilter = () => {
        setOpenFilter(false);
    };

    const fetchData = async () => {
        const response = await sendGetShopifyPackages(user.token);
        return {response: response, data: await response.json()};
    };

    useEffect(() => {
        fetchData().then(({response, data}) => {
            if (response.ok) {
                data.packages.forEach(pack => pack.imagePath = API_URL + "/" + pack.imagePath,
                )
                setPackages(data.packages);
            } else
                enqueueSnackbar(data.message, {variant: "error"});
        });
    }, []);

    return (
        <Page title="Dashboard: Packages">
            <Container maxWidth="xl">
                <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end"
                       sx={{mb: 5}}>
                    {/* <Stack direction="row" spacing={1} flexShrink={0} sx={{my: 1}}>
                        <PackageFilterSidebar
                            isOpenFilter={openFilter}
                            onOpenFilter={handleOpenFilter}
                            onCloseFilter={handleCloseFilter}
                        />
                        <PackageSort/>
                    </Stack> */}
                </Stack>

                <PackageList packages={packages}/>
            </Container>
        </Page>
    );
}

import {useRef, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
// material
import {Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Button} from '@mui/material';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function OrderMoreMenu({orderId, handleCreateInvoice, invoiceAvailable, handleMarkAsReturned, dateOfROG,
                                          handleUnmarkAsReturned, handleUncreateInvoice}) {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                <Iconify icon="eva:more-vertical-fill" width={20} height={20}/>
            </IconButton>

            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: {width: 200, maxWidth: '100%'},
                }}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                <MenuItem sx={{color: 'text.secondary'}} disabled={invoiceAvailable} onClick={async () => {
                    await handleCreateInvoice(orderId);
                    setIsOpen(false);
                }}>
                    <ListItemIcon>
                        <Iconify icon="ic:baseline-plus" width={24} height={24}/>
                    </ListItemIcon>
                    <ListItemText primary="Create invoice" primaryTypographyProps={{variant: 'body2'}}/>
                </MenuItem>

                <MenuItem sx={{color: 'text.secondary'}} disabled={dateOfROG != null} onClick={async () => {
                    await handleMarkAsReturned(orderId);
                    setIsOpen(false);
                }}>
                    <ListItemIcon>
                        <Iconify icon="mdi:clipboard-arrow-down-outline" width={24} height={24}/>
                    </ListItemIcon>
                    <ListItemText primary="Mark as returned" primaryTypographyProps={{variant: 'body2'}}/>
                </MenuItem>

                <MenuItem sx={{color: 'text.secondary'}} disabled={!invoiceAvailable} onClick={async () => {
                    await handleUncreateInvoice(orderId);
                    setIsOpen(false);
                }}>
                    <ListItemIcon>
                        <Iconify icon="mdi:clipboard-arrow-down-outline" width={24} height={24}/>
                    </ListItemIcon>
                    <ListItemText primary="Undo invoice" primaryTypographyProps={{variant: 'body2'}}/>
                </MenuItem>

                <MenuItem sx={{color: 'text.secondary'}} disabled={dateOfROG == null} onClick={async () => {
                    await handleUnmarkAsReturned(orderId);
                    setIsOpen(false);
                }}>
                    <ListItemIcon>
                        <Iconify icon="mdi:clipboard-arrow-down-outline" width={24} height={24}/>
                    </ListItemIcon>
                    <ListItemText primary="Undo returned" primaryTypographyProps={{variant: 'body2'}}/>
                </MenuItem>

            </Menu>
        </>
    );
}

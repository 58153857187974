import {useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import Page from "../components/Page";
import {
    Autocomplete, Button, Card, CardActionArea, CardActions, CardMedia,
    Container,
    Grid, IconButton,
    Paper,
    Stack,
    Typography
} from "@mui/material";
import {
    sendGetShopifyPackage,
    sendGetShopifyProducts, sendUpdatePackagePhoto,
    sendUpdateShopifyPackage
} from "../api/user";
import AuthContext from "../context/authContext";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {useSnackbar} from "notistack";
import {API_URL} from "../api/constants";
import Iconify from "../components/Iconify";

const Package = () => {
    let {packageId} = useParams();
    const {user} = useContext(AuthContext);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [_package, setPackage] = useState(null);
    const [products, setProducts] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [reRender, setReRender] = useState(null);

    const fetchData = async (packageId) => {
        const response = await sendGetShopifyPackage(user.token, packageId);
        return {response: response, data: await response.json()};
    };

    const fetchProductData = async (packageId) => {
        const response = await sendGetShopifyProducts(user.token);
        return {response: response, data: await response.json()};
    };

    const handleSavePackage = async () => {
        const response = await sendUpdateShopifyPackage(user.token, parseInt(packageId), _package.items.map(item => {
            return {id: item.id, quantity: item.quantity}
        }));
        if (response.ok)
            enqueueSnackbar((await response.json())?.message, {variant: "success"});
        else
            enqueueSnackbar((await response.json())?.message, {variant: "error"});
    };

    useEffect(() => {
        fetchData(packageId).then(({response, data}) => {
            if (response.ok) {
                const result = data.package;
                const modifiedObj = {
                    id: result.id,
                    name: result.name,
                    createdAt: result.createdAt,
                    imagePath: API_URL + "/" + result.imagePath,
                    items:
                        result?.packageProducts?.map((x) => {
                            return {
                                id: x.product.id,
                                name: x.product.name,
                                quantity: x.quantity,

                            };
                        }) || []
                }
                setPackage(modifiedObj);
            } else
                enqueueSnackbar(data.message, {variant: "error"});


            fetchProductData().then(({response, data}) => {
                setAllProducts(data.products);
                setProducts(data.products);
            });
        });

    }, [reRender]);

    const handlePlus = (item) => {
        item.quantity++;
        setPackage({..._package});
    }

    const handleMinus = (item) => {
        item.quantity--;/*
        if (item.quantity === 0)
            for (const i in _package.items)
                if (_package.items[i].id === item.id) {
                    _package.items.splice(i, 1);
                    break;
                }*/
        setPackage({..._package});
    }

    const onFileChange = async (event) => {

        const formData = new FormData();

        formData.append("image", event.target.files[0], event.target.files[0].name);
        const response = await sendUpdatePackagePhoto(user.token, packageId, formData);
        if (response.ok)
            enqueueSnackbar((await response.json())?.message, {variant: "success"});
        else
            enqueueSnackbar((await response.json())?.message, {variant: "error"});

        setReRender(new Date().getTime());
    };

    return (
        <Page title="Dashboard: Package">
            <Container maxWidth="xl">
                <Paper sx={{padding: 5}}>
                    <Grid container sx={{pb: 5}} spacing={4}>
                        <Grid item xs={12} md={6}>

                            <Typography variant="h3">
                                Package view
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                                <Typography variant="h6">
                                    Add product to the package:
                                </Typography>
                                <Autocomplete
                                    disablePortal
                                    options={products.map(x => {
                                        return {id: x.id, label: `${x.name} (#${x.id})`};
                                    })}

                                    onChange={
                                        (event, value) => {
                                            const item = _package.items.find(x => x.id === value.id);
                                            if (item == null) {
                                                _package.items = [..._package.items, {
                                                    id: value.id,
                                                    name: value.label,
                                                    quantity: 1
                                                }];
                                            } else {
                                                if (item.quantity == 0)
                                                    item.quantity++;
                                            }
                                            setPackage({..._package});
                                        }
                                    }

                                    sx={{width: 300}}
                                    renderInput={(params) => <TextField {...params} label="Product"/>}
                                />
                            </Stack>
                        </Grid>
                    </Grid>

                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <input
                                accept="image/*"
                                hidden={true}
                                id="raised-button-file"
                                type="file"
                                onChange={onFileChange}
                            />
                            <Card sx={{maxWidth: "300px", borderRadius: 0, mb: 2}}>

                                <CardActionArea component="label" htmlFor="raised-button-file">
                                    <CardMedia sx={{objectFit: "cover", objectPosition: "center"}}
                                               component="img"
                                               width="300px"
                                               image={`${_package?.imagePath}`}
                                               height="300px"
                                               alt="package image"
                                    />
                                    <CardActions>

                                        <Typography variant="body2" color="#616161">Click the image to change
                                            it</Typography>
                                    </CardActions>
                                </CardActionArea>

                            </Card>


                            <Typography gutterBottom variant="h5" component="div">
                                {_package?.name} (#{_package?.id})
                            </Typography>
                            <Typography variant="body1">
                                Created on:
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {new Date(_package?.createdAt).toLocaleDateString("en-GB")}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Box height="90%" sx={{p: 0}}>
                                <Stack spacing={2}>
                                    {_package?.items?.filter(x => x.quantity > 0).map(x =>
                                        <Stack key={x.id} direction="row" sx={{display: "flex", alignItems: "center"}}
                                               spacing={1}>
                                            <Typography variant="h6"
                                                        sx={{maxWidth: "50%", width: "50%"}}>{x?.name}
                                            </Typography>
                                            <Typography variant="body1">{x?.quantity}</Typography>
                                            <Button variant="contained" color="primary"
                                                    onClick={() => handlePlus(x)}>+</Button>
                                            <Button variant="contained" color="error"
                                                    onClick={() => handleMinus(x)}>-</Button>

                                        </Stack>
                                    )}
                                </Stack>
                            </Box>

                            <Box sx={{textAlign: "right"}}>
                                <Button variant="contained"
                                        onClick={() => handleSavePackage()}>Save</Button>
                            </Box>

                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Page>
    )
        ;
}

export default Package;

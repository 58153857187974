import {
    API_USER,
    API_USER_DELETE_ARCHIVES,
    API_USER_GET_ARCHIVES,
    API_USER_GET_ORDERS,
    API_USER_LOGS,
    API_USER_SHOPIFY_PACKAGES,
    API_USER_SHOPIFY_PRODUCTS,
    API_USER_SHOPIFY_WAREHOUSES,
    API_USER_UPDATE_SHOPIFY_PACKAGES,
    API_USER_UPLOAD_FILE
} from "./constants";

// sends a get myself request
// returns the response & response body containing current archives data
export const sendGetMyself = async (token) => {
    return await fetch(API_USER, {
        method: 'GET',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json', "Authorization": "Bearer " + token},
    });
};

// sends a upload file request
// returns the response & response body containing response data
export const sendUploadFileRequest = async (token, data, country, shopifyOrder = false) => {
    return await fetch(API_USER_UPLOAD_FILE + new URLSearchParams({
        country: shopifyOrder ? "Shopify" : country,
    }), {
        method: 'POST',
        headers: {"Authorization": "Bearer " + token},
        body: data
    });
};


// sends a get archive request
// returns the response which contains a body with id, fileName, deleteDate etc..
export const sendGetArchives = async (token, page, rowsPerPage, fileName, orderBy, order, archiveStatus) => {
    return await fetch(API_USER_GET_ARCHIVES, {
        method: 'POST',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json', "Authorization": "Bearer " + token},
        body: JSON.stringify({
            page: page, rowsPerPage: rowsPerPage, fileName: fileName,
            orderBy: orderBy, order: order, status: archiveStatus
        })
    });
};

export const sendGetOrders = async (token, filterOptions) => {
    return await fetch(API_USER_GET_ORDERS, {
        method: 'POST',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json', "Authorization": "Bearer " + token},
        body: JSON.stringify(filterOptions)
    });
};

export const sendCreatePlaceholderInvoice = async (token, orderId) => {
    return await fetch(API_USER_GET_ORDERS + orderId + "/invoice", {
        method: 'POST',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json', "Authorization": "Bearer " + token}
    });
};

export const sendUncreatePlaceholderInvoice = async (token, orderId) => {
    return await fetch(API_USER_GET_ORDERS + orderId + "/unInvoice", {
        method: 'POST',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json', "Authorization": "Bearer " + token}
    });
};

export const sendSetDateOfRog = async (token, orderId) => {
    return await fetch(API_USER_GET_ORDERS + orderId + "/rog", {
        method: 'POST',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json', "Authorization": "Bearer " + token},
        body: JSON.stringify({dateOfROG: new Date()})
    });
};

export const sendUnsetDateOfRog = async (token, orderId) => {
    return await fetch(API_USER_GET_ORDERS + orderId + "/unrog", {
        method: 'POST',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json', "Authorization": "Bearer " + token},
    });
};

export const sendDeleteArchives = async (token, archiveIds) => {
    return await fetch(API_USER_DELETE_ARCHIVES, {
        method: 'POST',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json', "Authorization": "Bearer " + token},
        body: JSON.stringify({ids: archiveIds})
    });
};

export const sendGetShopifyPackages = async (token) => {
    return await fetch(API_USER_SHOPIFY_PACKAGES, {
        method: 'GET',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json', "Authorization": "Bearer " + token},
    });
};

export const sendGetShopifyPackage = async (token, id) => {
    return await fetch(API_USER_SHOPIFY_PACKAGES + id, {
        method: 'GET',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json', "Authorization": "Bearer " + token},
    });
};

export const sendUpdateShopifyPackage = async (token, id, packageItems) => {
    return await fetch(API_USER_UPDATE_SHOPIFY_PACKAGES, {
        method: 'POST',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json', "Authorization": "Bearer " + token},
        body: JSON.stringify({packageId: id, packageItems})
    });
};

export const sendGetShopifyProducts = async (token, filterOptions) => {
    let url = API_USER_SHOPIFY_PRODUCTS;

    if (filterOptions) {
        url = url.substring(0, url.length - 1) + `?page=${filterOptions.page}&perPage=${filterOptions.rowsPerPage}`
    }

    return await fetch(url, {
        method: 'GET',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json', "Authorization": "Bearer " + token},
    });
};

export const sendCreateShopifyProduct = async (token, name) => {
    return await fetch(API_USER_SHOPIFY_PRODUCTS, {
        method: 'POST',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json', "Authorization": "Bearer " + token},
        body: JSON.stringify({name})
    });
};

export const sendEditShopifyProduct = async (token, productId, name) => {
    return await fetch(API_USER_SHOPIFY_PRODUCTS + productId, {
        method: 'PUT',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json', "Authorization": "Bearer " + token},
        body: JSON.stringify({name})
    });
};

export const sendDeleteShopifyProduct = async (token, productId) => {
    return await fetch(API_USER_SHOPIFY_PRODUCTS + productId, {
        method: 'DELETE',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json', "Authorization": "Bearer " + token},
    });
};

export const sendGetShopifyWarehouses = async (token) => {
    return await fetch(API_USER_SHOPIFY_WAREHOUSES, {
        method: 'GET',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json', "Authorization": "Bearer " + token},
    });
};

export const sendGetShopifyWarehouse = async (token, warehouseId) => {
    return await fetch(API_USER_SHOPIFY_WAREHOUSES + warehouseId, {
        method: 'GET',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json', "Authorization": "Bearer " + token},
    });
};

export const sendUpdateShopifyWarehouse = async (token, warehouseId, fromWarehouseId = null, products) => {
    return await fetch(API_USER_SHOPIFY_WAREHOUSES, {
        method: 'POST',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json', "Authorization": "Bearer " + token},
        body: JSON.stringify({warehouseId, fromWarehouseId, products})
    });
};

export const sendUpdatePackagePhoto = async (token, packageId, image) => {
    return await fetch(API_USER_SHOPIFY_PACKAGES + packageId + "/photo", {
        method: 'POST',
        headers: {'Accept': 'application/json', "Authorization": "Bearer " + token},
        body: image
    });
};

export const sendGetLogs = async (token, filterOptions) => {
    return await fetch(API_USER_LOGS, {
        method: 'POST',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json', "Authorization": "Bearer " + token},
        body: JSON.stringify(filterOptions),
    });
};

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {IconButton, Tooltip, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Iconify from "../../../components/Iconify";


const StockPreview = ({products, setProducts}) => {
    if (products.length == 0)
        return <Typography variant="h6">
            No products to show.
        </Typography>;

    return (
        <Paper sx={{width: '100%', overflow: 'hidden'}}>
            <TableContainer sx={{maxHeight: '70vh'}}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{backgroundColor: 'white'}} sx={{width: "50px"}}>Id</TableCell>
                            <TableCell style={{backgroundColor: 'white'}} sx={{width: "200px"}}>Name</TableCell>
                            <TableCell style={{backgroundColor: 'white'}} sx={{width: "50px"}}>Quantity</TableCell>
                            <TableCell style={{backgroundColor: 'white'}} sx={{width: "50px"}}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {products.map((row) => (
                            <TableRow
                                key={row.name}

                            >
                                <TableCell>
                                    {row.id}
                                </TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.quantity}</TableCell>
                                <TableCell>
                                    <Tooltip title="Delete">
                                        <IconButton onClick={() => {
                                            setProducts((products) => products.filter(x => x.id != row.id))
                                        }}>
                                            <Iconify icon="eva:trash-2-outline"/>
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>


                </Table>
            </TableContainer>
        </Paper>
    );
}

export default StockPreview;

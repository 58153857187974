import * as React from 'react';
import Button from '@mui/material/Button';
import {
    Autocomplete,
    Checkbox,
    Grid,
    Paper,
    Stack,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {useContext, useEffect, useState} from "react";
import {sendGetShopifyProducts, sendGetShopifyWarehouses, sendUpdateShopifyWarehouse} from "../../../api/user";
import AuthContext from "../../../context/authContext";
import StockPreview from "./StockPreview";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useSnackbar} from "notistack";


export default function TransferList() {
    const {user} = useContext(AuthContext);
    const [warehouses, setWarehouses] = useState([]);
    const [toWarehouse, setToWarehouse] = useState(null);
    const [product, setProduct] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [fromWarehouse, setFromWarehouse] = useState(null);
    const [activeStep, setActiveStep] = React.useState(0);
    const [products, setProducts] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [confirm, setConfirm] = useState(false);

    const handleAdd = (e) => {
        product.quantity = quantity;
        console.log(product);
        setProducts((products) => [...products, product]);
        setProduct(null);
        setQuantity(1);
    };

    const steps = [
        {
            label: 'Select from which warehouse to transfer product',
            component: <Stack spacing={2} py={2}>
                <Typography>If no warehouse is selected stock will just be created as new</Typography>
                <Autocomplete
                    disablePortal
                    options={warehouses}
                    value={fromWarehouse}
                    sx={{width: 300}}

                    onChange={(event, value) => {
                        setFromWarehouse(value);
                    }}
                    renderInput={(params) => <TextField {...params} label="From warehouse"/>}
                /></Stack>,
            check: true
        },
        {
            label: 'Select to which warehouse to transfer product',
            component:
                <Autocomplete
                    disablePortal
                    options={warehouses.filter(x => x.id != fromWarehouse?.id)}
                    sx={{width: 300}}
                    value={toWarehouse}
                    onChange={(event, value) => {
                        setToWarehouse(value);

                    }}
                    renderInput={(params) => <TextField {...params} label="To warehouse"/>}
                />,
            check: toWarehouse != null

        },
        {
            label: 'Select the product and quantity',
            component: <Box display="flex" gap={2.5}>
                <Autocomplete
                    disablePortal
                    value={product}
                    options={allProducts.filter(y => !products.map(z => z.id).includes(y.id)).map(x => {
                        return {id: x.id, label: `${x.name} (#${x.id})`, name: x.name};
                    })}
                    onChange={(event, value) => {
                        setProduct(value);
                    }}
                    sx={{width: 300}}
                    renderInput={(params) => <TextField {...params} label="Product"/>}
                />
                <TextField label="Quantity" variant="outlined"
                           onChange={(event) => setQuantity(event.target.value)}
                           sx={{width: 100}}
                           value={quantity}
                />
                <Button id="outlined-basic" variant="contained"
                        disabled={product == null || !Number.isInteger(parseInt(quantity))}
                        onClick={handleAdd}>Add</Button>

            </Box>,
            check: toWarehouse != null

        },

    ];

    const fetchData = async () => {
        const response = await sendGetShopifyWarehouses(user.token);
        const data = await response.json();
        return {response, data};
    };

    const fetchProductData = async (packageId) => {
        const response = await sendGetShopifyProducts(user.token);
        return {response: response, data: await response.json()};
    };

    useEffect(() => {
        fetchData().then(({response, data}) => {
            if (response.ok) {
                for (const warehouse of data.warehouses)
                    warehouse.label = warehouse.name;
                setWarehouses(data.warehouses);
            } else {
                enqueueSnackbar(data.message, {variant: "error"});
            }
        });

        fetchProductData().then(({response, data}) => {
            setAllProducts(data.products);
        });
    }, []);


    useEffect(() => {
        if(activeStep == 0)
            setToWarehouse(null);
        if (activeStep < 2)
            setProducts([]);
    }, [activeStep]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };


    const handleSubmit = async (event) => {
        const response = await sendUpdateShopifyWarehouse(user.token, toWarehouse?.id, fromWarehouse?.id,
            products.map(x => {
                return {productId: x.id, quantity: x.quantity}
            }));
        const data = await response.json();

        if (response.ok)
            enqueueSnackbar(data.message, {variant: "success"});
        else
            enqueueSnackbar(data.message, {variant: "error"});

        if (response.ok) {
            setProducts([]);
            setFromWarehouse(null);
            setActiveStep(0);
            setToWarehouse(null);
            setConfirm(false);
        }
    };

    return (
        <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
                <Stack spacing={5}>
                    <Typography variant="h4">Stock changes selection</Typography>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => (
                            <Step key={step.label}>
                                <StepLabel
                                    optional={
                                        index === steps.length - 1 ? (
                                            <Typography variant="caption">Once you click transfer changes will be made
                                                to
                                                stock</Typography>
                                        ) : null
                                    }
                                >
                                    {step.label}
                                </StepLabel>
                                <StepContent>
                                    {step.component}
                                    <Box sx={{mb: 2}}>
                                        <div>
                                            <Button
                                                variant="contained"
                                                onClick={handleNext}
                                                disabled={!step.check}
                                                sx={{
                                                    mt: 1,
                                                    mr: 1,
                                                    visibility: activeStep == steps.length - 1 ? 'hidden' : ''
                                                }}
                                            >
                                                {index === steps.length - 1 ? 'Transfer' : 'Next'}
                                            </Button>
                                            <Button
                                                disabled={index === 0}
                                                onClick={handleBack}
                                                sx={{mt: 1, mr: 1}}
                                            >
                                                Back
                                            </Button>
                                        </div>
                                    </Box>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </Stack>
            </Grid>


            <Grid item xs={12} md={6} sx={{visibility: activeStep > 1 ? "" : "hidden"}}>
                <Stack spacing={5}>
                    <Box>
                        <Typography variant="h4">Stock changes preview</Typography>
                        <Typography

                            variant="body1">{fromWarehouse == null ? "None" : `${fromWarehouse?.name} (#${fromWarehouse?.id})`} -> {toWarehouse?.name} (#{toWarehouse?.id})</Typography>
                    </Box>
                    <StockPreview products={products} setProducts={setProducts}/>
                    {products.length > 0 ?
                        <Box display="flex" justifyContent="flex-end">
                            <FormControlLabel control={<Checkbox checked={confirm}
                                                                 onChange={(event, checked) => setConfirm(checked)}/>}
                                              label="I confirm what I entered is valid"/>
                            <Button variant="contained" disabled={!confirm} onClick={handleSubmit}>Submit</Button>
                        </Box> : null}
                </Stack>
            </Grid>
        </Grid>


    );
}

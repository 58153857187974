import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Orders from './pages/Orders';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import DashboardApp from './pages/DashboardApp';
import Archives from "./pages/Archives";
import Packages from "./pages/Packages";
import Package from "./pages/Package";
import Warehouse from "./pages/Warehouse";
import Logs from "./pages/Logs";
import Warehouses from "./pages/Warehouses";
import Products from "./pages/Products";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'archive', element: <Archives /> },
        { path: 'orders', element: <Orders /> },
        { path: 'products', element: <Products /> },
        { path: 'warehouses', element: <Warehouses /> },
        { path: 'packages', element: <Packages /> },
        { path: 'warehouse', element: <Warehouse /> },
        { path: 'logs', element: <Logs /> },
        { path: 'package/:packageId', element: <Package /> },

      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/orders" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}

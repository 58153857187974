import {useContext, useEffect, useState} from 'react';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {OrderListHead, OrderListToolbar, OrderMoreMenu} from '../sections/@dashboard/orders';
// mock
import AuthContext from "../context/authContext";
import {Autocomplete, Container, Grid, Typography} from "@mui/material";
import TextField from "@mui/material/TextField";
import {sendGetShopifyWarehouses} from "../api/user";
import {useSnackbar} from "notistack";
import ProductStockTable from "../sections/@dashboard/warehouses/ProductStockTable";
import {value} from "lodash/seq";
// ----------------------------------------------------------------------


export default function Warehouses() {
    const {user} = useContext(AuthContext);
    const [createProductOpen, setCreateProductOpen] = useState(false);
    const [warehouses, setWarehouses] = useState([]);
    const [warehouse, setWarehouse] = useState(null);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [initialLoad, setInitialLoad] = useState(true);

    const fetchData = async () => {
        const response = await sendGetShopifyWarehouses(user.token);
        const data = await response.json();
        return {response, data};
    };

    useEffect(() => {
        fetchData().then(({response, data}) => {
            if (response.ok) {
                for (const warehouse of data.warehouses)
                    warehouse.label = warehouse.name;
                setWarehouses(data.warehouses);

                if (initialLoad === true) {
                    setWarehouse(data.warehouses[0]);
                    setInitialLoad(false);
                }

            } else {
                enqueueSnackbar(data.message, {variant: "error"});
            }
        });
    }, []);

    return (
        <Page title="Product">
            <Container maxWidth="xl">
                <Grid container rowSpacing={3}>
                    <Grid item xs={12}>
                        <Grid container alignItems="left" rowSpacing={2}>
                            <Grid item sm={12} xs={12}
                                  sx={{

                                      display: "flex",
                                      justifyContent: {sm: "flex-start", xs: "flex-start"}
                                  }}>
                                <Autocomplete
                                    disablePortal
                                    options={warehouses}
                                    sx={{width: 300}}
                                    onChange={(event, value) => {
                                        setWarehouse(value);
                                    }}
                                    value={warehouse}
                                    renderInput={(params) => <TextField {...params} label="Select a warehouse"/>}
                                />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <ProductStockTable warehouse={warehouse}/>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Container>
        </Page>
    );
}

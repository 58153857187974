import PropTypes from 'prop-types';
// material
import {styled} from '@mui/material/styles';
import {
    Toolbar,
    Tooltip,
    IconButton,
    Typography,
    OutlinedInput,
    InputAdornment,
    Button,
    Box, Grid, TextField, Autocomplete, Stack, Collapse, Checkbox, Divider
} from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
import {useState} from "react";
import Container from "@mui/material/Container";
import Drawer from '@mui/material/Drawer';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {DatePicker} from '@mui/x-date-pickers';
import {IOrderInvoiceAvailable} from "../../../models/IOrderFilters.ts";
import {API_USER_GET_ORDERS, API_USER_UPLOAD_FILE} from "../../../api/constants";
import {sendGetOrders} from "../../../api/user";
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({theme}) => ({
    height: 30,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({theme}) => ({
    width: 240,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {width: 320, boxShadow: theme.customShadows.z8},
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
    },
}));

// ----------------------------------------------------------------------

OrderListToolbar.propTypes = {
    numSelected: PropTypes.number,
    filterName: PropTypes.string,
    onFilterName: PropTypes.func,
};

export default function OrderListToolbar({
                                             selected,
                                             numSelected,
                                             onFilterName,
                                             handleDeleteOpen,
                                             searchPlaceholderText,
                                             filters,
                                             setFilters,
                                             setPage,
                                             exportTable,
                                             exportLoading
                                         }) {
    const [drawer, toggleDrawer] = useState(false);

    return (
        <>
            <RootStyle
                sx={{
                    ...(numSelected > 0 && {
                        color: 'primary.main',
                        bgcolor: 'primary.lighter',
                    }),
                }}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs} sx={{my: 2}}>

                    <Drawer
                        anchor={"right"}
                        open={drawer}
                        onClose={() => toggleDrawer(false)}
                    >
                        <Stack sx={{p: 3}} spacing={3}>
                            <Typography variant="h6">Filters</Typography>
                            <Divider/>
                            <Stack spacing={1}>
                                <Typography variant="body1">Search orders: </Typography>
                                <SearchStyle
                                    value={filters.shippingCode}
                                    onChange={(e) => {
                                        setFilters((currentState) => ({
                                            ...currentState,
                                            shippingCode: e.target.value
                                        }));
                                    }}
                                    placeholder={searchPlaceholderText}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <Iconify icon="eva:search-fill"
                                                     sx={{color: 'text.disabled', width: 20, height: 20}}/>
                                        </InputAdornment>
                                    }
                                />
                            </Stack>

                            <Stack spacing={1}>
                                <Typography variant="body1">Invoice available: </Typography>
                                <Autocomplete
                                    id="combo-box-demo"
                                    disableClearable
                                    options={[IOrderInvoiceAvailable.All, IOrderInvoiceAvailable.Yes, IOrderInvoiceAvailable.No]}
                                    value={filters.invoiceAvailable}
                                    onChange={(e, value) => {
                                        setFilters((currentState) => ({
                                            ...currentState,
                                            invoiceAvailable: value
                                        }));
                                    }}
                                    renderInput={(params) => <TextField {...params}
                                                                        label="Invoice available"/>}
                                />
                            </Stack>

                            <Stack spacing={1}>
                                <Typography variant="body1">Returned? </Typography>
                                <Autocomplete
                                    value={filters.returned == null ? "Both" : filters.returned ? "Yes" : "No"}
                                    onChange={(e, value) => {
                                        console.log(value);
                                        if (value.length != 0) // TODO: make sure he can't select nothing
                                        {
                                            setFilters((currentState) => ({
                                                ...currentState,
                                                returned: value == "Both" ? null : value == "Yes" ? true : false
                                            }));
                                        }
                                    }}

                                    options={["Both", "Yes", "No"]}
                                    defaultValue={"Both"}
                                    renderInput={(params) => <TextField {...params} label="Returned"/>}
                                />


                            </Stack>

                            <Stack spacing={1}>
                                <Typography variant="body1">Country: </Typography>
                                <Autocomplete
                                    id="combo-box-demo"
                                    disableClearable
                                    options={["Global", "Serbia", "Montenegro", "BiH", "Croatia"]}
                                    value={filters.country}
                                    onChange={(e, value) => {
                                        setFilters((currentState) => ({...currentState, country: value}));
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Country"/>}
                                />
                            </Stack>

                            <Stack spacing={1}>
                                <Typography variant="body1">Shipped between: </Typography>
                                <DatePicker
                                    label="From"
                                    inputFormat="DD/MM/YYYY"
                                    value={filters.dateMin}
                                    onChange={(newValue) => {
                                        setFilters((currentState) => ({
                                            ...currentState,
                                            dateMin: new Date(newValue)
                                        }));
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />

                                <DatePicker
                                    inputFormat="DD/MM/YYYY"
                                    label="To"
                                    value={filters.dateMax}
                                    onChange={(newValue) => {
                                        setFilters((currentState) => ({
                                            ...currentState,
                                            dateMax: new Date(newValue)
                                        }));
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Stack>

                            <Box>
                                <Button onClick={() => {
                                    setFilters({
                                        shippingCode: "",
                                        returned: false,
                                        country: "Global",
                                        invoiceAvailable: "All",
                                        dateMin: null,
                                        dateMax: null
                                    });
                                    setPage(0);
                                }}> Reset filters</Button>
                            </Box>

                        </Stack>
                    </Drawer>
                </LocalizationProvider>

                {numSelected > 0 ? (
                    <Typography component="div" variant="subtitle1">
                        {numSelected} selected
                    </Typography>
                ) : (
                    null
                )}

                <Container></Container>


                <Button
                    style={{marginRight: '12px'}}
                    variant="contained"
                    component="label"
                    color={"info"}
                    startIcon={<Iconify icon="ic:outline-sim-card-download"/>}
                    onClick={() => exportTable()}
                    disabled={exportLoading}
                >
                  {exportLoading ? (
                    'Downloading...'
                  ) : 'Export'}

                </Button>
                <Button
                    variant="contained"
                    component="label"
                    color={"primary"}
                    startIcon={<Iconify icon="ic:round-filter-list"/>}
                    onClick={() => toggleDrawer(!drawer)}
                >
                    Filter
                </Button>

            </RootStyle>
        </>
    );
}

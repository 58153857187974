import PropTypes from 'prop-types';
// material
import {alpha, styled} from '@mui/material/styles';
import {Box, Stack, AppBar, Toolbar, IconButton, Link, Avatar, Typography} from '@mui/material';
// components
import Iconify from '../../components/Iconify';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import {Link as RouterLink} from "react-router-dom";
import account from "../../_mock/account";
import {useContext} from "react";
import AuthContext from "../../context/authContext";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({theme}) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    },
}));

const ToolbarStyle = styled(Toolbar)(({theme}) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
    onOpenSidebar: PropTypes.func,
};


const AccountStyle = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.grey[500_12],
}));


export default function DashboardNavbar({onOpenSidebar}) {
    const {user} = useContext(AuthContext);

    return (
        <RootStyle>
            <ToolbarStyle>
                <IconButton onClick={onOpenSidebar} sx={{mr: 1, color: 'text.primary', display: {lg: 'none'}}}>
                    <Iconify icon="eva:menu-2-fill"/>
                </IconButton>

                {/* <Searchbar />*/}
                <Box sx={{flexGrow: 1}}/>

                <Stack direction="row" alignItems="center" spacing={{xs: 0.5, sm: 1.5}}>
                    <Box>
                        <Link underline="none" component={RouterLink} to="#">
                            <AccountStyle>
                                <Avatar src={account.photoURL} alt="photoURL"  sx={{ width: 30, height: 30 }}/>
                                <Box sx={{ml: 2}}>
                                    <Typography variant="subtitle2" sx={{color: 'text.primary'}}>
                                        {user.username}
                                    </Typography>
                                </Box>
                            </AccountStyle>
                        </Link>
                    </Box> {/*<NotificationsPopover />
          <AccountPopover />*/}
                </Stack>
            </ToolbarStyle>
        </RootStyle>
    );
}

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useContext, useEffect, useState} from "react";
import {sendDeleteShopifyProduct, sendGetShopifyProducts} from "../../../api/user";
import AuthContext from "../../../context/authContext";
import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TablePagination,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import {useSnackbar} from "notistack";
import ProductsTableMore from "./ProductsTableMore";
import EditProduct from "./EditProduct";


export default function ProductTable({reRender, setReRender}) {
    const {user} = useContext(AuthContext);
    const [products, setProducts] = useState([]);
    const [productCount, setProductCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = React.useState(0);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [editProductId, setEditProductId] = useState(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editProductName, setEditProductName] = useState("");
    const [deleteProductId, setDeleteProductId] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const fetchData = async () => {
        const response = await sendGetShopifyProducts(user.token, {
            page,
            rowsPerPage,
        });
        return {response, data: await response.json()};
    };

    useEffect(() => {
        fetchData().then(({response, data}) => {
            if (response.ok) {
                setProducts(data.products);
                setProductCount(data.productCount);
            } else
                enqueueSnackbar(data.message, {variant: "error"});
        });
    }, [page, rowsPerPage, reRender]);

    const handleChangePage = (
        event,
        newPage
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleEditProductOpen = (productId, productInitialName) => {
        setEditProductId(productId);
        setEditProductName(productInitialName);
        setEditModalOpen(true);
    }

    const handleDeleteProductOpen = (productId) => {
        setDeleteProductId(productId);
        setDeleteDialogOpen(true);
    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
    };

    const handleDeleteConfirm = async () => {
        await deleteProduct(deleteProductId);
        setDeleteDialogOpen(false);
        setDeleteProductId(null);
    };

    const deleteProduct = async () => {
        const response = await sendDeleteShopifyProduct(user.token, deleteProductId);
        const result = await response.json();

        if (response.ok)
            setReRender(new Date().getTime());
        else
            enqueueSnackbar(result.message, {variant: "error"});
    };

    if (products.length === 0)
        return <Box display="flex" justifyContent="flex-start">
            <Typography variant="h4" textAlign="center">
                No products found.
            </Typography>
        </Box>;

    return (
        <Paper>
            <EditProduct
                productId={editProductId}
                initialProductName={editProductName}
                shown={editModalOpen}
                setShown={setEditModalOpen}
                setReRender={setReRender}
            ></EditProduct>

            <Dialog
                open={deleteDialogOpen}
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Are you sure you want to proceed with deletion?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" color={"red"} fontStyle={"italic"} fontSize={"20px"} fontWeight={"bold"}>
                        Deleting this product will remove it from all packages and change the stock of all warehouses if any exist.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel}>Cancel</Button>
                    <Button color={"error"} onClick={handleDeleteConfirm} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{width: "50px"}}>ID</TableCell>
                            <TableCell sx={{width: "200px"}}>Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {products.map((row) => (
                            <TableRow
                                key={row.id}
                            >
                                <TableCell>
                                    {row.id}
                                </TableCell>
                                <TableCell>
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">
                                    <ProductsTableMore productId={row.id} productName={row.name} handleEditProductOpen={handleEditProductOpen} handleDeleteProductOpen={handleDeleteProductOpen}/>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>


                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={productCount}
                rowsPerPageOptions={[5,10,25, 100]}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}

import {useContext, useEffect, useRef, useState} from 'react';

// material
import {
    Card,
    Container, Modal,
    Skeleton,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography
} from "@mui/material";
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import AuthContext from "../context/authContext";
import Box from "@mui/material/Box";
import {sendGetLogs} from "../api/user";
import * as PropTypes from "prop-types";
import {Icon} from "@iconify/react";
import LogsMore from "../sections/@dashboard/logs/LogsMore";

// ----------------------------------------------------------------------


function RootStyle(props) {
    return null;
}

RootStyle.propTypes = {
    sx: PropTypes.shape({}),
    children: PropTypes.node
};


export default function Logs() {
    const TABLE_HEAD = [
        {id: 'id', label: 'ID', alignRight: false},
        {id: 'username', label: 'User', alignRight: false},
        {id: 'type', label: 'Log Type', alignRight: false},
        {id: 'createdAt', label: 'Logged At', alignRight: false},
        {id: ''},
    ];

    const visuallyHidden = {
        border: 0,
        margin: -1,
        padding: 0,
        width: '1px',
        height: '1px',
        overflow: 'hidden',
        position: 'absolute',
        whiteSpace: 'nowrap',
        clip: 'rect(0 0 0 0)',
    };

    const {user} = useContext(AuthContext);

    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [logs, setLogs] = useState([]);
    const [order, setOrder] = useState('DESC');
    const [orderBy, setOrderBy] = useState('createdAt');
    const [logCount, setLogCount] = useState(0);
    const [reRender, setReRender] = useState(0);

    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [viewDataModalOpen, setviewDataModalOpen] = useState(false);
    const [currentViewData, setCurrentViewData] = useState(null);

    const [drawer, toggleDrawer] = useState(false);
    const [filters, setFilters] = useState({
        type: null,
        order: order,
        orderBy: orderBy,
        dateMin: null,
        dateMax: null,
    });

    const handleViewDataOpen = (data) => {
        setCurrentViewData(data);
        setviewDataModalOpen(true);
    };

    const changeLogTypes = [
        'Archive Uploaded',
        'Archive Deleted',
        'Order Marked for Return',
        'Order Unmarked for Return',
        'Order Invoice Created',
        'Order Invoice Deleted',
        'Product Created',
        'Package Updated',
        'Warehouse Updated',
        'Stock Moved from Warehouse',
        'Product Edited',
        'Product Deleted',
    ]

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - logCount) : 0;

    const fetchLogsData = async () => {
        const response = await sendGetLogs(user.token, {
            page,
            rowsPerPage,
            ...filters
        });
        const result = await response.json();
        return {response, result};
    };

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        // setOrder(isAsc ? 'desc' : 'asc');
        // setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const formatDatetime = (date) => {
        let d = new Date(date);
        return d.getDate().toString().padStart(2, '0') + "-" + (d.getMonth() + 1).toString().padStart(2, '0') + "-" + d.getFullYear() + " " +
            d.getHours().toString().padStart(2, '0') + ":" + d.getMinutes().toString().padStart(2, '0');
    }

    useEffect(() => {
        setLoading(true);
        fetchLogsData().then(({response, result}) => {
            if (response.ok) {
                setLogCount(result.logCount);
                setLogs(result.logs);
                setLoading(false);
            } else {
                console.log('error');
            }
        })

    }, [page, rowsPerPage, reRender]);

    return (
        <Page title="Logs">
            <Container maxWidth="xl">
                <Modal
                    open={viewDataModalOpen}
                    onClose={() => setviewDataModalOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{
                        display: "flex", justifyContent: "center", alignItems: "center"
                    }}
                >
                    <Box sx={{
                        width: "600px",
                        maxHeight: "800px",
                        backgroundColor: "white",
                        p: 2,
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <Typography variant="h4">Log Data</Typography>
                            <Box component={Icon} icon={"mdi:close"} width={30} height={30} onClick={() => {
                                setviewDataModalOpen(false);
                            }}/>
                        </div>
                        <Box sx={{
                            backgroundColor: "white",
                            height: "100%",
                            pt: 3,
                            pb: 1.5,
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <pre>{
                                JSON.stringify(JSON.parse(currentViewData), null, 4)
                            }</pre>
                        </Box>
                    </Box>
                </Modal>

                <Card>
                    {/*<ToolbarStyle>*/}
                    {/*    <Container></Container>*/}

                    {/*    <Button*/}
                    {/*        variant="contained"*/}
                    {/*        component="label"*/}
                    {/*        color={"primary"}*/}
                    {/*        startIcon={<Iconify icon="ic:round-filter-list"/>}*/}
                    {/*        onClick={() => toggleDrawer(!drawer)}*/}
                    {/*    >*/}
                    {/*        Filter*/}
                    {/*    </Button>*/}
                    {/*</ToolbarStyle>*/}

                    <Scrollbar>
                        <TableContainer sx={{minWidth: 1000, maxHeight: '62vh'}}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        {TABLE_HEAD.map((headCell) => (
                                            <TableCell
                                                style={{backgroundColor: 'white'}}
                                                key={headCell.id}
                                                align={headCell.alignRight ? 'right' : 'left'}
                                                sortDirection={orderBy === headCell.id ? order : false}
                                                padding="normal"
                                            >
                                                <TableSortLabel
                                                    hideSortIcon
                                                    active={orderBy === headCell.id}
                                                    direction={orderBy === headCell.id ? order : 'asc'}
                                                    onClick={handleRequestSort(headCell.id)}
                                                >
                                                    {headCell.label}
                                                    {orderBy === headCell.id ? (
                                                        <Box
                                                            sx={{...visuallyHidden}}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                {loading ?
                                    <TableBody>

                                        {[...Array(rowsPerPage).keys()].map(x => <TableRow>
                                            <TableCell padding="checkbox">
                                                <Skeleton/>
                                            </TableCell>
                                            {[...Array(10).keys()].map(x => {
                                                return (<TableCell padding="normal">
                                                        <Skeleton/>
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>)}

                                    </TableBody> :

                                    <TableBody>

                                        {logs.map((row) => {
                                            const {
                                                id,
                                                user,
                                                type,
                                                createdAt,
                                                data
                                            } = row;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={id}
                                                    tabIndex={-1}
                                                >
                                                    <TableCell component="th" scope="row" padding="normal">
                                                        <Typography variant="subtitle2" noWrap>
                                                            {loading ? <Skeleton variant="text" animation="pulse"/>
                                                                : id}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell component="th" scope="row" padding="normal">
                                                        <Typography variant="subtitle2" noWrap>
                                                            {loading ? <Skeleton variant="text" animation="pulse"/>
                                                                : user.username}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell component="th" scope="row" padding="normal">
                                                        <Typography variant="subtitle2" noWrap>
                                                            {loading ? <Skeleton variant="text" animation="pulse"/>
                                                                : changeLogTypes[type]}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell component="th" scope="row" padding="normal">
                                                        <Typography variant="subtitle2" noWrap>
                                                            {loading ? <Skeleton variant="text" animation="pulse"/>
                                                                : formatDatetime(createdAt)}
                                                        </Typography>
                                                    </TableCell>


                                                    <TableCell align="right">
                                                        <LogsMore handleViewDataOpen={handleViewDataOpen} data={data}/>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}

                                        {emptyRows > 0 && (
                                            <TableRow style={{height: 53 * emptyRows}}>
                                                <TableCell colSpan={6}/>
                                            </TableRow>)}

                                    </TableBody>
                                }
                                {!loading && logs.length === 0 ?
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{py: 3}}>
                                                <SearchNotFound searchQuery={filters.type}/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody> : null
                                }
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        component="div"
                        count={logCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </Page>
    );
}

import AuthContext from "./authContext";
import {sendLoginRequest} from "../api/auth";
import {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {sendGetMyself} from "../api/user";
import ReactLoading from "react-loading";

// Protected Routing

export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigation = useNavigate();

    const getUser = async (token) => {
        const response = await sendGetMyself(token);
        if (response.ok) {
            const data = await response.json();
            data.user.token = token;
            return data.user;
        } else {
            await logout();
        }
    };

    const login = async (username, password, rememberMe) => {
        const {response, data} = await sendLoginRequest(username, password);
        if (response.ok) {
            if (rememberMe)
                localStorage.setItem('JWT', data.token);
            else
                sessionStorage.setItem("JWT", data.token);
            const userData = await getUser(data.token);
            if (userData)
                setUser(userData);
            navigation("/");
        }
        return {response, data};
    };

    const logout = async () => {
        localStorage.removeItem("JWT");
        sessionStorage.removeItem("JWT");
        setUser(null)
        navigation("/login");
    };

    useEffect(() => {
        const doStuff = async () => {
            let token = sessionStorage.getItem('JWT');

            if (!token || token.length < 10)
                token = localStorage.getItem("JWT");

            if (token) {
                const userData = await getUser(token);
                if (userData)
                    setUser(userData);
                return userData;
            } else {
                navigation("/login");
            }
        };

        doStuff().then((data) => {
            setLoading(false);
        });
    }, []);

    return (<AuthContext.Provider
        value={{
            authenticated: !!user, user, login, logout, loading
        }}
    >
        {children}
    </AuthContext.Provider>);
}

const LoadingScreen = () => {
    return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: '100vh'}}>
            <ReactLoading type="spin" color="#0000FF"
                          height={100} width={50}/>
        </div>
    );
}
// Protected route
export const ProtectedRoute = ({children}) => {
    const location = useLocation();
    const {authenticated, loading} = useContext(AuthContext);
    if (loading || (!authenticated && location.pathname !== '/login'))
        return <LoadingScreen/>;
    return children;
};

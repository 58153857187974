import {useRef, useState} from "react";
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import Iconify from "../../../components/Iconify";

const ProductsTableMore = ({productId, productName, handleEditProductOpen, handleDeleteProductOpen}) => {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                <Iconify icon="eva:more-vertical-fill" width={20} height={20}/>
            </IconButton>

            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: {width: 200, maxWidth: '100%'},
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <MenuItem sx={{color: 'text.secondary'}} onClick={ () => {
                    handleEditProductOpen(productId, productName);
                    setIsOpen(false);
                }}>
                    <ListItemIcon>
                        <Iconify icon="eva:edit-outline" width={24} height={24}/>
                    </ListItemIcon>
                    <ListItemText primary="Edit" primaryTypographyProps={{variant: 'body2'}}/>
                </MenuItem>

                <MenuItem sx={{color: 'text.secondary'}} onClick={ () => {
                    handleDeleteProductOpen(productId);
                    setIsOpen(false);
                }}>
                    <ListItemIcon>
                        <Iconify icon="eva:trash-2-outline" width={24} height={24}/>
                    </ListItemIcon>
                    <ListItemText primary="Delete" primaryTypographyProps={{variant: 'body2'}}/>
                </MenuItem>
                {/*<MenuItem sx={{color: 'text.secondary'}} onClick={() => {*/}
                {/*    handleViewDataOpen(data);*/}
                {/*    setIsOpen(false);*/}
                {/*}}>*/}
                {/*    <ListItemIcon>*/}
                {/*        <Iconify icon="eva:eye-outline" width={24}*/}
                {/*                 height={24}/>*/}
                {/*    </ListItemIcon>*/}
                {/*    <ListItemText primary="View Data"*/}
                {/*                  primaryTypographyProps={{variant: 'body2'}}*/}
                {/*    />*/}
                {/*</MenuItem>*/}
            </Menu>
        </>
    )
};

export default ProductsTableMore;

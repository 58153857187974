// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22}/>;

const navConfig = [
    {
        title: 'orders',
        path: '/dashboard/orders', // TODO: UPDATE LATER
        icon: getIcon('eva:people-fill'),
    },
    {
        title: 'archives',
        path: '/dashboard/archive', // TODO: UPDATE LATER
        icon: getIcon('mdi:note-text-outline'),
    },

    {
        title: 'packages',
        path: '/dashboard/packages',
        icon: getIcon('eva:shopping-bag-fill'),
    },
    {
        title: 'products',
        path: '/dashboard/products',
        icon: getIcon('akar-icons:shipping-box-v1'),
    },
    {
        title: 'Warehouses',
        path: '/dashboard/warehouses',
        icon: getIcon('material-symbols:warehouse-rounded'),
    },
    {
        title: 'Warehouse Management',
        path: '/dashboard/warehouse',
        icon: getIcon('eva:options-2-outline'),
    },
    {
        title: 'Logs',
        path: '/dashboard/logs',
        icon: getIcon('mdi:file-document'),
    },

];

export default navConfig;

export enum IOrderInvoiceAvailable {
    All = "All",
    Yes = "Yes",
    No = "No"
}

export enum IOrderDeliveryStatus {
    InProgress = "In Progress",
    Delivered = "Delivered",
    Returned = "Returned"
}

export interface IOrderFilters {
    shippingCode: string;
    invoiceAvailable?: IOrderInvoiceAvailable;
    countries?: string[];
    deliveryStatus?: IOrderDeliveryStatus;
    dateMin?: Date;
    dateMax?: Date;
}
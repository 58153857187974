import {useContext, useEffect, useState} from 'react';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {OrderListHead, OrderListToolbar, OrderMoreMenu} from '../sections/@dashboard/orders';
// mock
import {Autocomplete, Button, Container, Grid, Typography} from "@mui/material";
import TransferList from "../sections/@dashboard/warehouse/TransferList";
// ----------------------------------------------------------------------


export default function Warehouse() {


    return (
        <Page title="Warehouse Management">
            <Container maxWidth="xl">
                <TransferList/>
            </Container>
        </Page>
    );
}

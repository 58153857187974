import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import FormLabel from "@mui/material/FormLabel";
import AuthContext from "../context/authContext";

const theme = createTheme();

export default function Login() {
  const {login, authenticated} = useContext(AuthContext);
  const navigation = useNavigate();

  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");


  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const username = formData.get('username');
    const password = formData.get('password');
    if (username.length < 4 || username.length > 20) {
      setError("Username must be at least 4-20 characters long.");
      return;
    }
    if (password.length < 6 || password.length > 32) {
      setError("Password must be 6-32 characters long.");
      return;
    }
    const {response, data} = await login(username, password, rememberMe);

    if (response.status === 401) { // wrong credentials
      setError(data.message);
    }
  };

  return (
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline/>
          <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
          >
            <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
            </Avatar>
            <Typography component="h1" variant="h5">
              Welcome back!
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
              <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  autoFocus
              />
              <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
              />
              <FormControlLabel
                  control={<Checkbox name="rememberMe" value="remember" color="primary"
                                     onChange={(e) => {
                                       setRememberMe(e.target.checked);
                                     }}/>}
                  label="Remember me"
              /><br/>
              <FormLabel style={{color: "red", textAlign: "center"}}>{error}</FormLabel>
              <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{mt: 3, mb: 2}}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/login#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
  );
}
import {API_LOGIN} from "./constants";

// sends a login request
// returns the response & response body containing JWT token
export const sendLoginRequest = async (username, password) => {
    const response = await fetch(API_LOGIN, {
        method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
    });
    return {response, data: (await response.json())};
};
import PropTypes from 'prop-types';
// material
import { Grid } from '@mui/material';
import PackageCard from './PackageCard';

// ----------------------------------------------------------------------

PackageList.propTypes = {
  products: PropTypes.array.isRequired
};

export default function PackageList({ packages, ...other }) {
  return (
    <Grid container spacing={3} {...other}>
      {packages.map((_package) => (
        <Grid key={_package.id} item xs={12} sm={6} md={4} xl={2}>
          <PackageCard _package={_package} />
        </Grid>
      ))}
    </Grid>
  );
}

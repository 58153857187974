// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
import {AuthProvider, ProtectedRoute} from "./context/authProvider";
// components
import ScrollToTop from './components/ScrollToTop';
import {BaseOptionChartStyle} from './components/chart/BaseOptionChart';
import {SnackbarProvider} from "notistack";

// ----------------------------------------------------------------------

export default function App() {
    return (
        <AuthProvider>
            <ProtectedRoute>
                <ThemeProvider>
                    <SnackbarProvider maxSnack={5}>
                        <ScrollToTop/>
                        <BaseOptionChartStyle/>
                        <Router/>
                    </SnackbarProvider>
                </ThemeProvider>
            </ProtectedRoute>
        </AuthProvider>
    );
}

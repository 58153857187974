import {useContext, useEffect, useRef, useState} from 'react';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {OrderListHead, OrderListToolbar, OrderMoreMenu} from '../sections/@dashboard/orders';
// mock
import AuthContext from "../context/authContext";
import CreateProduct from "../sections/@dashboard/products/CreateProduct";
import {Button, Container, Grid, Typography} from "@mui/material";
import {sendGetShopifyWarehouses} from "../api/user";
import ProductTable from "../sections/@dashboard/products/ProductTable";
import {useSnackbar} from "notistack";
// ----------------------------------------------------------------------


export default function Products() {
    const productTable = useRef();

    const {user} = useContext(AuthContext);
    const [createProductOpen, setCreateProductOpen] = useState(false);
    const [tableReRender, setTableReRender] = useState(null);

    const handleCreateProductOpen = (value) => {
        setCreateProductOpen(value);
        setTableReRender(new Date().getTime());
    }

    return (
        <Page title="Product">
            <Container maxWidth="xl">
                <Grid container rowSpacing={3}>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" rowSpacing={2}>
                            <Grid item sm={6} xs={12} sx={{
                                display: "flex",
                                justifyContent: "flex-start"
                            }}>
                                <Button onClick={() => setCreateProductOpen(true)} variant="contained">
                                    Create a new product
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <ProductTable reRender={tableReRender} setReRender={setTableReRender}/>
                    </Grid>
                </Grid>
                <CreateProduct shown={createProductOpen} setShown={handleCreateProductOpen}/>
            </Container>
        </Page>
    );
}

import {filter} from 'lodash';
import {useCallback, useContext, useEffect, useReducer, useRef, useState} from 'react';
// material
import {
    Card,
    Table,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Tooltip, Skeleton, Button,
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {OrderListHead, OrderListToolbar, OrderMoreMenu} from '../sections/@dashboard/orders';
// mock
import {
    sendCreatePlaceholderInvoice,
    sendGetOrders,
    sendSetDateOfRog,
    sendUncreatePlaceholderInvoice,
    sendUnsetDateOfRog
} from "../api/user";
import AuthContext from "../context/authContext";
import Loading from "react-loading";
import {useSnackbar} from "notistack";
import Iconify from "../components/Iconify";
// ----------------------------------------------------------------------

const TABLE_HEAD = [ // will be used for filtering later
    {id: 'id', label: 'Id', alignRight: false},
    {id: 'shippingCode', label: 'Shipping Code', alignRight: false},
    {id: 'shippingCost', label: 'Shipping Cost', alignRight: false},
    {id: 'shippingFee', label: 'Shipping Fee', alignRight: false},
    {id: 'shippingDate', label: 'Shipping date', alignRight: false},
    {id: 'country.name', label: 'Country', alignRight: false},
    {id: 'currencyCode', label: 'Currency Code', alignRight: false},
    {id: 'courier.courierName', label: 'Courier', alignRight: false},
    {id: 'dateOfROG', label: 'Returned', alignRight: false},
    {id: 'invoice.id', label: 'Invoice Available', alignRight: false},
    {id: ''},
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'DESC'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (archive) => archive.fileName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}


export default function Orders() {
    const {user} = useContext(AuthContext);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState('desc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('id');
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [orders, setOrders] = useState([]);
    const [archiveCount, setArchiveCount] = useState(0);
    const [reRender, setReRender] = useState(0);
    const [filters, setFilters] = useState({
        shippingCode: "",
        invoiceAvailable: "All",
        country: "Global",
        dateMin: null,
        dateMax: null,
        returned: null
    });
    const [exportLoading, setExportLoading] = useState(false);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deletionArchiveIds, setDeletionArchiveIds] = useState([]);

    const handleDeleteOpen = (archiveId) => {
        setDeletionArchiveIds(archiveId);
        setDeleteDialogOpen(true);
    };

    const handleCreateInvoice = async (orderId) => {
        const response = await sendCreatePlaceholderInvoice(user.token, orderId);
        setReRender(new Date().getTime());
    };

    const handleMarkAsReturned = async (orderId) => {
        const response = await sendSetDateOfRog(user.token, orderId);
        setReRender(new Date().getTime());
    };

    const handleUncreateInvoice = async (orderId) => {
        const response = await sendUncreatePlaceholderInvoice(user.token, orderId);
        if (!response.ok) {
            enqueueSnackbar((await response.json())?.message, {variant: "error"});
            return;
        }
        setReRender(new Date().getTime());
    };

    const handleUnmarkAsReturned = async (orderId) => {
        const response = await sendUnsetDateOfRog(user.token, orderId);
        setReRender(new Date().getTime());
    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
    };

    const handleDeleteOk = async () => {
        if (deletionArchiveIds.length === 0)
            return;

        //await deleteArchive(deletionArchiveIds);
        setDeleteDialogOpen(false);
        setDeletionArchiveIds([]);
    };

    const fetchOrdersData = async () => {
        const response = await sendGetOrders(user.token, {
            page,
            rowsPerPage,
            orderBy,
            order: order.toUpperCase(),
            ...filters
        });
        const result = await response.json();
        return {response, result};
    };

    const exportOrdersData = async () => {
        setExportLoading(true);
        const response = await sendGetOrders(user.token, {
            page,
            rowsPerPage,
            orderBy,
            order: order.toUpperCase(),
            ...filters,
            ignoreLimit: true
        });
        const result = await response.json();
        let newOrders = result.orders.reduce(function (acc, item) {
            acc.push({
                'id': item.id,
                'Shipping Code': item.shippingCode,
                'Shipping Cost': item.shippingCost,
                'Shipping Date': item.shippingDate,
                'Country': item.countryName,
                'Courier': item.courier.name,
                'Returned': item.dateOfROG ? true : false,
                'Invoice Available': item.invoiceAvailable
            });
            return acc
        }, [])

        const rows = [
            'id',
            'Shipping Code',
            'Shipping Cost',
            'Shipping Date',
            'Country',
            'Courier',
            'Returned',
            'Invoice Available'
        ];

        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += rows.join(",") + "\r\n";

        newOrders.forEach(function(rowObj) {
            let tempStr = ''
            rows.forEach(col => {
                tempStr += rowObj[col] + ',';
            });
            csvContent += tempStr + "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "table_export.csv");
        document.body.appendChild(link); // Required for FF

        link.click();
        setExportLoading(false);
    };

    const didMountRef = useRef(false);

    useEffect(() => {
        const rpp = localStorage.getItem("orderRows");
        if (rpp != null && Number.isInteger(Number.parseInt(rpp))) {
            setRowsPerPage(Number.parseInt(rpp));
            console.log(rpp);
        }

    }, []);

    useEffect(() => {
        setPage(0);
        setReRender(new Date().getTime());
    }, [filters, order, orderBy]);

    useEffect(() => {
        if (didMountRef.current) {
            setPage(0);
            setReRender(new Date().getTime());
            localStorage.setItem("orderRows", rowsPerPage);
        }
        didMountRef.current = true;
    }, [rowsPerPage]);

    useEffect(() => {
        setLoading(true);
        if(!rowsPerPage)
            return;
        fetchOrdersData().then(({response, result}) => {
            if (response.ok) {
                setArchiveCount(result.orderCount);
                setOrders(result.orders);
                setSelected([]);
                setLoading(false);
            } else {
                enqueueSnackbar(result.message, {variant: "error"});
            }
        });
    }, [page, reRender]);


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = orders.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - archiveCount) : 0;


    return (
        <Page title="Orders">
            <Container maxWidth="xl">
                <Card>
                    <OrderListToolbar numSelected={selected.length} filters={filters} setFilters={setFilters}
                                      selected={selected} setSelected={setSelected} handleDeleteOpen={handleDeleteOpen}
                                      setPage={setPage}
                                      exportTable={exportOrdersData}
                                      exportLoading={exportLoading}
                                      searchPlaceholderText="Search orders..."/>

                    <Scrollbar>
                        <TableContainer sx={{minWidth: 1000, maxHeight: '62vh'}}>
                            <Table stickyHeader>
                                <OrderListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={orders.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                {loading ?
                                    <TableBody>


                                        {[...Array(rowsPerPage ? rowsPerPage : 10).keys()].map(x => <TableRow>
                                            <TableCell padding="checkbox">
                                                <Skeleton/>
                                            </TableCell>
                                            {[...Array(10).keys()].map(x => {
                                                return (<TableCell padding="normal">
                                                        <Skeleton/>
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>)}

                                    </TableBody> :

                                    <TableBody>


                                        {orders.map((row) => {
                                            const {
                                                id,
                                                shippingCode,
                                                shippingCost,
                                                shippingFee,
                                                shippingDate,
                                                orderStatus,
                                                countryName,
                                                dateOfROG,
                                                currencyCode,
                                                courier,
                                                invoiceAvailable,
                                                createdAt
                                            } = row;
                                            const isItemSelected = selected.indexOf(id) !== -1;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={id}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                    selected={isItemSelected}
                                                    aria-checked={isItemSelected}

                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox checked={isItemSelected}
                                                                  onChange={(event) => handleClick(event, id)}/>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" padding="normal">

                                                        <Typography variant="subtitle2" noWrap>
                                                            {loading ? <Skeleton variant="text" animation="pulse"/>
                                                                : id}
                                                        </Typography>

                                                    </TableCell>
                                                    <TableCell component="th" scope="row" padding="normal"
                                                               align="left">

                                                        <Typography variant="subtitle2" noWrap>
                                                            {loading ? <Skeleton variant="text" animation="pulse"/>
                                                                : shippingCode}
                                                        </Typography>


                                                    </TableCell>
                                                    <TableCell component="th" scope="row" padding="normal" align="left">
                                                        <Typography variant="subtitle2" noWrap>
                                                            {loading ? <Skeleton variant="text" animation="pulse"/>
                                                                : parseInt(shippingCost)}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" padding="normal">
                                                        <Typography variant="subtitle2" noWrap>
                                                            {loading ? <Skeleton variant="text" animation="pulse"/>
                                                                : parseInt(shippingFee)}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" padding="normal">
                                                        <Typography variant="subtitle2" noWrap>
                                                            {loading ? <Skeleton variant="text" animation="pulse"/>
                                                                : new Date(shippingDate).toLocaleDateString("en-GB")}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell component="th" scope="row" padding="normal">
                                                        <Typography variant="subtitle2" noWrap>
                                                            {loading ? <Skeleton variant="text" animation="pulse"/>
                                                                : countryName}
                                                        </Typography>
                                                    </TableCell>
                                                    {/*    <TableCell minWidth={500}>
                                                    <Label variant="ghost"
                                                           color={orderStatus == 0 ? "info" : "success"}>
                                                        {orderStatus == 0 ? "Ordered" : "Delivered"}
                                                    </Label>
                                                </TableCell>*/}

                                                    <TableCell component="th" scope="row" padding="normal">
                                                        <Typography variant="subtitle2" noWrap>
                                                            {loading ? <Skeleton variant="text" animation="pulse"/>
                                                                : currencyCode}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" padding="normal">
                                                        <Typography variant="subtitle2" noWrap>
                                                            {loading ? <Skeleton variant="text" animation="pulse"/>
                                                                : courier.name}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell component="th" scope="row" padding="normal">
                                                        <Tooltip title="XDD" arrow>
                                                            {loading ? <Skeleton variant="text" animation="pulse"/>
                                                                : (dateOfROG != null ?
                                                                    <Label variant="ghost"
                                                                           color="error">
                                                                        Yes
                                                                    </Label>
                                                                    : <div/>)}

                                                        </Tooltip>
                                                    </TableCell>

                                                    <TableCell component="th" scope="row" padding="normal">
                                                        {loading ? <Skeleton variant="text" animation="pulse"/>
                                                            : <Label variant="ghost"
                                                                     color={invoiceAvailable ? "success" : "error"}>
                                                                {invoiceAvailable ? "Yes" : "No"}
                                                            </Label>}

                                                    </TableCell>
                                                    {/*
                                                <TableCell
                                                    align="left">{deletedBy != null ? deletedBy.username : "-"}</TableCell>
*/}
                                                    <TableCell align="right">
                                                        <OrderMoreMenu
                                                            invoiceAvailable={invoiceAvailable}
                                                            handleCreateInvoice={handleCreateInvoice}
                                                            handleUncreateInvoice={handleUncreateInvoice}
                                                            handleUnmarkAsReturned={handleUnmarkAsReturned}
                                                            dateOfROG={dateOfROG}
                                                            handleMarkAsReturned={handleMarkAsReturned}
                                                            orderId={id}/>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}


                                        {emptyRows > 0 && (
                                            <TableRow style={{height: 53 * emptyRows}}>
                                                <TableCell colSpan={6}/>
                                            </TableRow>)}

                                    </TableBody>
                                }
                                {!loading && orders.length === 0 ?
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{py: 3}}>
                                                <SearchNotFound searchQuery={filters.shippingCode}/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody> : null
                                }
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        component="div"
                        count={archiveCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </Page>
    );
}

// Constants for the API

const URL = 'https://api.hellosmiledash.xyz'; // api's url, HTTPS in production!
const API_PORT = 80;

export const API_URL = `${URL}/`;

export const API_LOGIN = API_URL + 'auth/login/'; // POST {username, password}

export const API_USER = API_URL + 'users/'; // GET Auth: Bearer jwt
export const API_USER_UPLOAD_FILE = API_URL + "users/files?";
export const API_USER_GET_ARCHIVES = API_URL + "users/archives/";
export const API_USER_DELETE_ARCHIVES = API_URL + "users/archives/delete";

export const API_USER_GET_ORDERS = API_URL + "users/orders/";

export const API_USER_SHOPIFY_PACKAGES = API_URL + "users/shopify/packages/";
export const API_USER_UPDATE_SHOPIFY_PACKAGES = API_URL + "users/shopify/packages/update/";

export const API_USER_SHOPIFY_PRODUCTS = API_URL + "users/shopify/products/";
export const API_USER_SHOPIFY_WAREHOUSES = API_URL + "users/shopify/warehouse/";

export const API_USER_LOGS = API_URL + "users/logs/";

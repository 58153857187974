import {filter} from 'lodash';
import {useCallback, useContext, useEffect, useReducer, useRef, useState} from 'react';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Modal,
    Box,
    Grid,
    TableHead,
    Paper,
    Icon,
    CircularProgress,
    DialogTitle,
    DialogContent,
    DialogContentText, DialogActions, Dialog, Autocomplete, TextField, FormGroup,
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import {ArchiveListHead, ArchiveListToolbar, ArchiveMoreMenu} from '../sections/@dashboard/archives';
// mock
import {sendDeleteArchives, sendGetArchives, sendUploadFileRequest} from "../api/user";
import AuthContext from "../context/authContext";
import {useDropzone} from "react-dropzone";
import {IArchiveFilterStatus} from "../models/IArchiveFilterStatus.ts";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useSnackbar} from "notistack";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    {id: 'id', label: 'Id', alignRight: false},
    {id: 'fileName', label: 'File name', alignRight: false},
    {id: 'addedBy', label: 'Added by', alignRight: false},
    {id: 'createdAt', label: 'Created at', alignRight: false},
    {id: 'status', label: 'Status', alignRight: false},
    {id: 'deletedBy', label: 'Deleted by', alignRight: false},
    {id: ''},
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (archive) => archive.fileName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

const filesReducer = (state, action) => {
    switch (action.type) {
        case "ADD_FILE": // action.file
            let present = false;
            for (let i = 0; i < state.length; i++)
                if (state[i].path === action.file.path) {
                    present = true;
                    break;
                }
            if (!present)
                state.push(action.file);
            return [...state];
        case "REMOVE_FILE": // action.file
            for (let i = 0; i < state.length; i++)
                if (state[i] === action.file) {
                    state.splice(i, 1);
                    break;
                }
            return [...state];
        case "REMOVE_ALL_FILES":
            state.clear();
            return [...state];
        case "SET_FILES_PROCESSING": // action.processin
            for (let i = 0; i < state.length; i++)
                if (state[i].processing === undefined)
                    state[i].processing = action.processing;
            return [...state];
        case "SET_FILE_PROCESSING": // action.processing, action.filename
            if (action.index < 0 || action.index >= state.length)
                return [...state];
            for (let i = 0; i < state.length; i++)
                if (action.filename.startsWith(state[i].name)) { // TODO: very important todo, must not do checking like this!!!!!!!!!!
                    state[i].processing = action.processing;
                    state[i].errorMessage = action.errorMessage;
                }
            return [...state];
        default:
            return [...state];
    }
};

export default function Archives() {
    const {user} = useContext(AuthContext);
    const [uploading, setUploading] = useState(false);
    const [country, setCountry] = useState(null);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('desc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('id');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [archives, setArchives] = useState([]);
    const [files, dispatchFiles] = useReducer(filesReducer, []);
    const [archiveCount, setArchiveCount] = useState(0);
    const [archiveStatus, setArchiveStatus] = useState([IArchiveFilterStatus.Processed, IArchiveFilterStatus.Deleted]);

    const [reRender, setReRender] = useState(0);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [shopifyOrder, setShopifyOrder] = useState(false);
    const [deletionArchiveIds, setDeletionArchiveIds] = useState([]);

    const handleDeleteOpen = (archiveId) => {
        setDeletionArchiveIds(archiveId);
        setDeleteDialogOpen(true);
    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
    };

    const handleDeleteOk = async () => {
        if (deletionArchiveIds.length === 0)
            return;

        await deleteArchive(deletionArchiveIds);
        setDeleteDialogOpen(false);
        setDeletionArchiveIds([]);
    };

    const fetchArchiveData = async () => {
        const response = await sendGetArchives(user.token, page, rowsPerPage, filterName, orderBy, order, archiveStatus);
        const result = await response.json();
        return {response, result};
    };

    useEffect(() => {
        fetchArchiveData().then(({response, result}) => {
            if (response.ok) {
                setArchiveCount(result.archiveCount);
                setArchives(result.archives);
                setSelected([]);
            } else {
                enqueueSnackbar(result.message, {variant: "error"});
            }
        });
    }, [page, rowsPerPage, filterName, reRender, order, orderBy, archiveStatus]);

    const deleteArchive = async (archiveIds) => {
        const response = await sendDeleteArchives(user.token, archiveIds);
        const result = await response.json();
        if (response.ok)
            setReRender(new Date().getTime());
        else
            enqueueSnackbar(result.message, {variant: "error"});
    };

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            dispatchFiles({type: "ADD_FILE", file: file});
        });
    }, [])

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: {
            "text/csv": [],
            "text/xml": [],
            "application/vnd.ms-excel.sheet.macroEnabled.12": [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            "application/vnd.ms-excel": []
        },
        onDrop
    });

    const acceptedFileItems = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const fileRejectionItems = fileRejections.map(({file, errors}) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
            <ul>
                {errors.map(e => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));


    const handleUploadFiles = async () => { // If file doesn't upload it is because it is the same file twice
        let notEmpty = false;
        for (const file of files) {
            if (file.processing === undefined)
                notEmpty = true;
        }
        if (!notEmpty) { // TODO: better error
            enqueueSnackbar("No files selected!", {variant: 'error'});
            return;
        }

        const formData = new FormData();
        for (const [index, value] of files.entries())
            if (value.processing === undefined)
                formData.append("report", value, value.name);

        setUploading(true);
        dispatchFiles({type: "SET_FILES_PROCESSING", processing: "progress"})
        const response = await sendUploadFileRequest(user.token, formData, country, shopifyOrder);
        const results = await response.json();
        if (response.ok) {
            for (const result of results.messages)
                dispatchFiles({
                    type: "SET_FILE_PROCESSING",
                    filename: result.filename,
                    processing: result.status,
                    errorMessage: result.status == "error" ? result.message : undefined
                });
        } else {
            enqueueSnackbar(results.message, {variant: "error"});
            dispatchFiles({type: "SET_FILES_PROCESSING", processing: undefined});
        }
        setUploading(false);
        setReRender(new Date().getTime());
    }


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = archives.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - archiveCount) : 0;

    const button = <Button
        variant="contained"
        component="label"
        color="info"
        startIcon={<Iconify icon="eva:plus-fill" sx={{color: "primary"}}/>}
        onClick={() => setUploadModalOpen(true)}
    >
        Upload
    </Button>;

    return (
        <Page title="Archives">
            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between">


                    {/*The upload modal*/}
                    <Modal
                        open={uploadModalOpen}
                        onClose={() => setUploadModalOpen(false)}
                        aria-labelledby="Upload Modal"
                        aria-describedby="Modal used for uploading files"
                        disableEnforceFocus
                    >
                        {/* TODO: fix scroll bar, make it look more natural*/}
                        <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            overflowY: "scroll",
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 1000,
                            alignItems: "center",
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            maxHeight: "90%",
                            p: 4
                        }}>
                            <Grid container spacing={2} direction="column"
                                  alignItems="center"
                                  justifyContent="center">
                                <Grid item xs={12} sx={{
                                    border: 1,
                                    borderColor: "#00000066",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <Box {...getRootProps({className: 'dropzone'})} sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        py: 10,
                                        px: 5
                                    }}>
                                        <input {...getInputProps()} />
                                        <Typography variant={"h4"}>Drag 'n' drop some files here, or click to select
                                            files</Typography>
                                        <Typography sx={{fontStyle: 'italic'}}>(Only *.xls, *.xlsx, *.xlsm, *.csv, *.xml
                                            files will be
                                            accepted)</Typography>
                                        <Iconify icon="mdi:plus-box" width={120} height={120}/>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={shopifyOrder}
                                                                             onChange={(event) => setShopifyOrder(event.target.checked)}/>}
                                                          label="Shopify data"/>
                                    </FormGroup>
                                </Grid>
                                {!shopifyOrder ?
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            onChange={(e, value) => {
                                                setCountry(value);
                                            }}
                                            disablePortal
                                            id="combo-box-demo"
                                            options={["Serbia", "Croatia", "Montenegro", "BiH"]}
                                            sx={{width: 300}}
                                            renderInput={(params) => <TextField {...params} label="Country"/>}
                                        />

                                    </Grid> : null}
                                <Grid item xs={12}>
                                    <h4>Files</h4>
                                </Grid>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Filename</TableCell>
                                                    <TableCell>Action</TableCell>
                                                    <TableCell>Processed</TableCell>
                                                    <TableCell>Message</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {files.map((file) => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                {file.name}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Button variant="text"
                                                                        disabled={file.processing !== undefined}
                                                                        onClick={() => {
                                                                            dispatchFiles({
                                                                                type: "REMOVE_FILE",
                                                                                file: file
                                                                            });
                                                                        }}>Remove</Button>
                                                            </TableCell>
                                                            {/* sry should be a functional component */}
                                                            <TableCell align={"right"}>
                                                                {file.processing !== undefined ? (
                                                                    file.processing === "progress" ?
                                                                        <CircularProgress size={25}/> : (
                                                                            file.processing === "ok" ?
                                                                                <Iconify icon="gridicons:checkmark"
                                                                                         color="green"/> : (file.processing === "error" ?
                                                                                    <Iconify icon="bi:x"
                                                                                             color="red"/> : null))
                                                                ) : null}
                                                            </TableCell>

                                                            <TableCell align={"right"}>

                                                                {file.processing === "error" ? (
                                                                        <Button
                                                                            onClick={() =>
                                                                                enqueueSnackbar(file.errorMessage.join("\n"), {variant: "error"})}>Show
                                                                        </Button>)
                                                                    : null}
                                                            </TableCell>
                                                        </TableRow>);
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item>


                                    <Button variant="outlined" sx={{m: 2}}

                                            onClick={() => setUploadModalOpen(false)}>Close</Button>
                                    <Button title="You must provide a country of the files!" color="primary"
                                            variant="contained" sx={{m: 2}} disabled={uploading}
                                            disabled={files.find(x => x.processing == null) == null || (country == null && shopifyOrder == false)}
                                            onClick={() => handleUploadFiles()}>Upload</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Modal>

                </Stack>

                {/*Delete dialog*/}
                <Dialog
                    open={deleteDialogOpen}
                    onClose={handleDeleteCancel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Are you sure you want to proceed with deletion?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Please confirm your action.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteCancel}>Cancel</Button>
                        <Button color={"error"} onClick={handleDeleteOk} autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>


                <Card>
                    <ArchiveListToolbar numSelected={selected.length} filterName={filterName} uploadButton={button}
                                        deleteArchive={deleteArchive}
                                        selected={selected} setSelected={setSelected}
                                        handleDeleteOpen={handleDeleteOpen}
                                        setArchiveStatus={setArchiveStatus} archiveStatus={archiveStatus}
                                        onFilterName={handleFilterByName} searchPlaceholderText="Search archives..."/>

                    <Scrollbar>
                        <TableContainer sx={{minWidth: 800, maxHeight: '62vh'}}>
                            <Table stickyHeader>
                                <ArchiveListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={archives.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {archives.map((row) => {
                                        const {id, fileName, user, deletedBy, createdAt} = row;
                                        const isItemSelected = selected.indexOf(id) !== -1;

                                        return (
                                            <TableRow
                                                hover
                                                key={id}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox checked={isItemSelected}
                                                              onChange={(event) => handleClick(event, id)}/>
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="none">
                                                    <Typography variant="subtitle2" noWrap>
                                                        {id}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="none">
                                                    <Typography variant="subtitle2" noWrap>
                                                        {fileName}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left">{user.username}</TableCell>
                                                <TableCell
                                                    align="left">{new Date(createdAt).toLocaleDateString("en-GB")}</TableCell>
                                                <TableCell align="left"> <Label variant="ghost"
                                                                                color={(deletedBy == null && 'success') || 'error'}>
                                                    {deletedBy == null ? "PROCESSED" : "DELETED"}
                                                </Label>
                                                </TableCell>
                                                <TableCell
                                                    align="left">{deletedBy != null ? deletedBy.username : "-"}</TableCell>

                                                <TableCell align="right">
                                                    <ArchiveMoreMenu deleteArchive={deleteArchive}
                                                                     deleteDisabled={!!deletedBy}
                                                                     handleDeleteOpen={handleDeleteOpen}
                                                                     archiveId={id}/>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{height: 53 * emptyRows}}>
                                            <TableCell colSpan={6}/>
                                        </TableRow>
                                    )}
                                </TableBody>

                                {archives.length === 0 && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{py: 3}}>
                                                <SearchNotFound searchQuery={filterName}/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={archiveCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </Page>
    );
}
